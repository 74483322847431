@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');


.App .landing-div{
  background-color: rgba(80, 80, 80, 0.144);
}
.tabs > button {
font-size: 1rem;
}

.app_download_logos>img{
width: 126px;
height: 42px;
}


.App .landing-div{
width: 100%;
}
.App{
  width: 100%;
}
.App button , 
.App input{
border-radius: 0;
}

.Login_Social_Buttons > button{
border: 1px solid rgba(0, 0, 0, 0.185);
border-radius: 20px;
background: transparent;
color: rgba(0, 0, 0, 2.185);
}

.App .landing-div>div{
  background-color: white;
  margin: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
  }
  
.Heading{
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Barlow Condensed', sans-serif;
  letter-spacing: 2px;
}

.premium>div{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.premium div>p:first-of-type{
  font-weight: bold;
}

.App .Sub-Heading{
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.6rem;
}

.App .social{
  justify-content: space-evenly;
}
.App .social >img{
  width: 25px ;
  height: 25px;
}
